export default {
	data() {
		return {
			isPartner: false,
			storeSeq: this.$route.params.storeSeq * 1,
			storeName: '',
			advertiseInfo: [
				{ title: '프리미엄·기본 패키지의 광고한도액 관리 단위' },
				{
					title: '프리미엄·기본 패키지의 광고한도액 (파트너사)',
					tooltip:
						'프리미엄·기본 패키지 상품에 대한 광고한도액입니다.<br>관리 단위가 ‘상품그룹’일 때는 프리미엄·기본 패키지 상품의 광고한도액 총합이 표시됩니다.',
				},
			],
			regDt: '',
			updateDt: '',
			productGroup: {
				inf: [
					{ title: 'No.', model: 'idx', size: 3 },
					{ title: 'idx', key: 'productGroupIdx', size: 4 },
					{ title: '브랜드', key: 'brandName', size: 12, notCenter: true },
					{ title: '상품그룹', key: 'productGroupName', size: 20, notCenter: true },
					{ title: '월 협찬제한', key: 'monthlyMaxCount', size: 7 },
					{
						title: '판매여부',
						key: 'isSales',
						size: 8,
						tooltip:
							'패키지 정보를 관리 중인 상품그룹이면 체크해주세요.<br>실제 딜 오픈과 판매에 영향을 주진 않습니다.',
					},
					{ title: '패키지 정보', key: 'packageName', size: 14 },
					{ title: '광고한도액', key: 'adLimitAmt', size: 6 },
					{ title: '등록일', key: 'enrollDate', size: 12, notCenter: true },
					{ title: '업데이트일', key: 'updateDt', size: 12, notCenter: true },
				],
				res: {},
			},
		}
	},
	methods: {
		changePageAction(page = 1) {
			return postApi('/partnerSelect/partnerDetailProductGroupList', {
				storeSeq: this.storeSeq,
				paging: getPaging(page - 1, 30),
				orderBy: getOrder('isSales, enrollDate'),
			}).then(res => {
				res.list = res.list.map(v => {
					;['enrollDate', 'updateDt'].map(k => (v[k] = v[k] ? v[k].replace('T', ' ') : ''))
					v.isError = false
					v.monthlyMaxCount = comma(v.monthlyMaxCount)
					v.adLimitAmt = comma(v.adLimitAmt)
					return v
				})
				return res
			})
		},
		openMemo() {
			openMemoPopup('STORE', this)
		},
	},
}
