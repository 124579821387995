<template>
	<div>
		<div class="flex mb-4 relative">
			<b-button class="w-1/12" @click="page('partnerEdit', { storeSeq })" variant="info">수정 </b-button>
			<b-button class="w-1/12 ml-4" @click="openMemo" variant="gray">메모 </b-button>
			<ul class="ml-8 flex absolute" style="list-style: disc; top: 0.6em; right: 2em">
				<li class="mr-12">등록일: {{ regDt }}</li>
				<li>업데이트일: {{ updateDt }}</li>
			</ul>
		</div>
		<collapse title="계약 정보">
			<vtb :data="contractInfo" oneColumn titleSize="2" />
		</collapse>
		<collapse title="기본 정보">
			<vtb :data="basicInfo" oneColumn titleSize="2" />
		</collapse>
		<collapse title="담당자 정보">
			<tb :inf="picInfo.inf" :res="picInfo.data" nav="none" />
		</collapse>
		<collapse title="정산 정보">
			<vtb :data="bankInfo" oneColumn titleSize="2" />
		</collapse>
		<!--패키지, 광고한도액 정보-->
		<collapse initShow title="패키지, 광고한도액 정보">
			<vtb :key="advertiseInfo[1].cont" :data="advertiseInfo" oneColumn titleSize="3" />
			<tb class="mt-4" :inf="productGroup.inf" :pr="ths" :res="productGroup.res" limit="30" />
		</collapse>

		<b-button class="w-1/12 m-auto mt-6 block" @click="$router.go(-1)" variant="gray">목록</b-button>
	</div>
</template>

<script>
import collapse from 'comp/collapse'
import partnerMixIn from 'pages/partnerMixIn.js'

export default {
	components: { collapse },
	mixins: [partnerMixIn],
	data() {
		return {
			bankInfo: {},
			basicInfo: {},
			contractInfo: {},
			picInfo: {},
		}
	},
	created() {
		postApi('/partnerSelect/partnerDetail', { storeSeq: this.storeSeq }).then(res => {
			let data = res.contractInfo
			//계약 정보
			this.contractInfo = [
				{ title: '계약담당자', cont: data.contractPicName },
				{ title: '계약일', cont: data.contractDate.replace('T', ' ') },
			]

			data = res.basicInfo
			//기본 정보
			this.basicInfo = [
				{ title: '사업자등록번호', cont: data.comSsn },
				{ title: '사업형태', cont: data.objectJoin },
				{ title: '주소', cont: data.comAddress },
				{ title: '웹사이트', cont: data.website },
				{ title: '대표전화', cont: data.comPhone },
				{ title: '대표자명', cont: data.comCeo },
			]

			//담당자 정보. 표 형태가 다르다
			this.picInfo = {
				inf: [
					{ title: '구분', key: 'kind', size: 6 },
					{ title: '이름', key: 'Name', size: 6 },
					{ title: '직급', key: 'Grade', size: 10 },
					{ title: '연락처1', key: 'Phone1', size: 12 },
					{ title: '연락처2', key: 'Phone2', size: 12 },
					{ title: '이메일', key: 'Email', size: 16 },
				],
				data: { list: [], paging: { totalCnt: 4 } },
			}
			;[
				{ name: '총괄', key: 'hott' },
				{ name: '발주', key: 'po' },
				{ name: 'CS', key: 'cs' },
				{ name: '정산', key: 'settlement' },
			].map(k => {
				let data = { kind: k.name + '담당자' }
				this.picInfo.inf.map(t => {
					if (t.key != 'kind') data[t.key] = res.picInfo[k.key + 'Pic' + t.key]
				})
				this.picInfo.data.list.push(data)
			})

			data = res.bankInfo
			//정산 정보
			this.bankInfo = [
				{ title: '은행 계좌', cont: data.bankAccount },
				{ title: '은행명', cont: data.bank },
				{ title: '은행 계좌주', cont: data.bankOwner },
				{ title: '세금계산서 발행용 이메일', cont: data.texEmail }, //tax...
			]

			//이건 표 형태도 다르고 아예 별개 취급해야 할듯
			data = res.advertiseInfo
			this.isPartner = data.advertiseManagementUnit == '파트너사'
			this.advertiseInfo[0].cont = data.advertiseManagementUnit
			this.advertiseInfo[1].cont = comma(data.adLimitAmt + '원')

			layout.pageTitle = layout.$route.meta.pageTitle + ' - ' + res.companyName
			this.storeName = res.companyName
			if (res.regDt) this.regDt = res.regDt.replace('T', ' ')
			if (res.updateDt) this.updateDt = res.updateDt.replace('T', ' ')

			this.changePage()
		})
	},
	methods: {
		changePage(page = 1) {
			this.changePageAction(page).then(res => {
				res.list = res.list.map(v => {
					if (v.isSales) {
						v.isSales = 'Y'
						if (this.isPartner && (v.packageName == '프리미엄 패키지' || v.packageName == '기본 패키지'))
							v.adLimitAmt = '-'
						else v.adLimitAmt = comma(v.adLimitAmt) + '원'
					} else {
						v.isSales = 'N'
						v.packageName = ''
						v.adLimitAmt = ''
					}
					return v
				})
				this.productGroup.res = res
				return res
			})
		},
	},
	computed: {},
}
</script>
