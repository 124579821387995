<template>
	<div class="collapseWrap">
		<div class="collapseHeader" @click="toggle">
			{{ title }}
			<b-icon class="absolute top-3 right-3" :rotate="iconRotate" icon="caret-down-fill" variant="gray" />
		</div>
		<b-collapse :visible="isShow"><slot /></b-collapse>
	</div>
</template>

<script>
export default {
	props: { initShow: { default: false }, title: { default: '' } },
	data() {
		return {
			isShow: false,
		}
	},
	methods: {
		toggle() {
			this.isShow = !this.isShow
			//this.collapse.toggle()
		},
	},
	mounted() {
		this.isShow = this.initShow !== false
		//if (this.isShow) this.collapse.show = true
	},
	computed: {
		iconRotate() {
			return this.isShow ? 180 : 0
		},
	},
}
</script>

<style></style>
